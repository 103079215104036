export const EVIDENCE = {
  CENIK: 'cenik',
  ATRIBUT: 'atribut',
  STITEK: 'stitek',
  SKUPZBOZ: 'skupina-zbozi',
  ADRESAR: 'adresar',
  DODAVATEL: 'dodavatel',
  ODBERATEL: 'odberatel',
  NABIDKA_PRIJATA: 'nabidka-prijata'
}

export const ATTRS = {
  EANS: ['EAN1', 'EAN2', 'EAN3', 'EAN4', 'EAN5'],
  ZNACKY: 'BRND',
  TYPY: 'TYPE',
  RADY: 'LINE',
  BQT: 'BQT',
  PQT: 'PQT',
  LQT: 'LQT'
}

export const TAGGROUPS = {
  ZNACKY : ATTRS.ZNACKY,
  TYPY : ATTRS.TYPY,
  RADY : ATTRS.RADY
}

export const CENIK_INCLUDES = {
  EANS : 'eans',
  PAL : 'pallets',
  SORT : 'sorting'
}
CENIK_INCLUDES.ALL = [CENIK_INCLUDES.EANS, CENIK_INCLUDES.PAL, CENIK_INCLUDES.SORT]

export const MJ = {
  ML: 'ml',
  KS: 'pcs',
  G: 'g',
}

export const OFR_TYPE = {
  REC : {
    STOCK: 'OFR.REC.STOCK',
    PREORD: 'OFR.REC.PREORD'
  }
}

export default {
  EVIDENCE,
  ATTRS,
  TAGGROUPS,
  CENIK_INCLUDES,
  MJ,
  OFR_TYPE
}
