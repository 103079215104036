import adresar from './adresar.js'
import cenik from './cenik.js'
import partnerCodes from './partner-codes.js'
import offerReceived from './offer-received.js'
import consts from './const.js'

export default {
  adresar,
  cenik,
  partnerCodes,
  offerReceived,

  EVIDENCE: consts.EVIDENCE,
  ATTS: consts.ATTRS,
  TAGGROUPS: consts.TAGGROUPS,
  OFR_TYPE: consts.OFR_TYPE
}
