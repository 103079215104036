import { api } from './flexiSession.js'
import { EVIDENCE } from './const.js'

let adresar = null

export async function get(nocache) {
  if (!adresar || nocache) {
    adresar = (await api.get(`/${EVIDENCE.ADRESAR}/(typVztahuK in ('typVztahu.odberDodav', 'typVztahu.odberatel', 'typVztahu.dodavatel', 'typVztahu.vyrobce')).json`, {
      detail: 'custom:kod,nazev,ulice,mesto,psc,email,mobil,ic,dic,poznam,stat,typVztahuK'
    })).adresar

    adresar.forEach(addr => {
      if (addr.stat.startsWith('code:')) {
        addr.stat = addr.stat.substr(5)
      }
    })
  }
  return adresar
}

export async function post(entry, test) {
  entry.stat = 'code:' + entry.stat
  if (entry.kod) {
    entry.id = 'code:' + entry.kod
    delete entry.kod
  }
  return await api.post(`/${EVIDENCE.ADRESAR}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    adresar: [entry]
  })
}

export default {
  get,
  post
}
