import { api } from './flexiSession.js'
import { EVIDENCE, ATTRS, TAGGROUPS } from './const.js'
import { getAttributes } from './common.js'

export async function get() {
  const cenik = (await api.get(`/${EVIDENCE.CENIK}.json`, {
    detail: 'custom:kod,eanKod'
  })).cenik

  const firma = (await api.get(`/${EVIDENCE.ADRESAR}/(typVztahuK in ('typVztahu.odberDodav', 'typVztahu.odberatel', 'typVztahu.dodavatel', 'typVztahu.vyrobce')).json`, {
    detail: 'custom:kod,typVztahuK'
  })).adresar

  const otherEans = await getAttributes(api, ATTRS.EANS)
  const dodavatele = (await api.get(`/${EVIDENCE.DODAVATEL}.json`, {
    detail: 'custom:firma,cenik,kodIndi'
  })).dodavatel
  const odberatele = (await api.get(`/${EVIDENCE.ODBERATEL}/(firma is not null).json`, {
    detail: 'custom:firma,cenik,kodIndi'
  })).odberatel

  const firmaHashMap = new Map()
  const dodavatelHashMap = new Map()
  const odberatelHashMap = new Map()
  const cenikHashMap = new Map()
  const cenikEanHashMap = new Map()

  for (const fir of firma) {
    firmaHashMap.set(fir.kod, fir)
  }

  for (const prod of cenik) {
    cenikHashMap.set(prod.kod, prod)
    cenikEanHashMap.set(prod.eanKod, prod)
  }

  for (const ean of otherEans) {
    ean.cenik = ean.cenik.substring(5)
    const prod = cenikHashMap.get(ean.cenik)
    if (!prod) {
      continue
    }
    if (!prod.moreEans) {
      prod.moreEans = []
    }
    prod.moreEans.push(ean.hodnota)
    cenikEanHashMap.set(ean.hodnota, prod)
  }

  for (const dod of dodavatele) {
    dod.cenik = dod.cenik.substring(5)
    dod.firma = dod.firma.substring(5)
    const prod = cenikHashMap.get(dod.cenik)
    const f = firmaHashMap.get(dod.firma)
    dod.kod = `${prod.kod}-${f.kod}`
    dod.cenikObj = prod
    dod.firmaObj = f

    if (!prod.dodavatele) {
      prod.dodavatele = []
    }
    prod.dodavatele.push(dod)
    if (!f.dodava) {
      f.dodava = []
    }
    f.dodava.push(dod)

    dodavatelHashMap.set(dod.kod, dod)
  }

  for (const odb of odberatele) {
    odb.cenik = odb.cenik.substring(5)
    odb.firma = odb.firma.substring(5)
    const prod = cenikHashMap.get(odb.cenik)
    const f = firmaHashMap.get(odb.firma)
    odb.kod = `${prod.kod}-${f.kod}`
    odb.cenikObj = prod
    odb.firmaObj = f

    if (!prod.odberatele) {
      prod.odberatele = []
    }
    prod.odberatele.push(odb)
    if (!f.odebira) {
      f.odebira = []
    }
    f.odebira.push(odb)

    odberatelHashMap.set(odb.kod, odb)
  }

  return {
    maps: {
      firma: firmaHashMap,
      cenik: cenikHashMap,
      dodavatel: dodavatelHashMap,
      odberatel: odberatelHashMap,
      cenikEan: cenikEanHashMap
    },
    lists: {
      firma: firma,
      cenik: cenik,
      odberatele: odberatele,
      dodavatele: dodavatele
    }
  }
}

export async function post(entry, odberatel, test) {
  const data = {
    firma: `code:${entry.firma}`,
    cenik: `code:${entry.cenik}`,
    kodIndi: entry.kodIndi
  }

  const evidence = odberatel ? EVIDENCE.ODBERATEL : EVIDENCE.DODAVATEL
  return await api.post(`/${evidence}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    [evidence]: [data]
  })
}

export default {
  get,
  post
}
