import { api } from './flexiSession.js'
import { EVIDENCE, CENIK_INCLUDES as CI } from './const.js'
import { getAttributes, getCenik as commonGetCenik} from './common.js'

export async function getPartnerWithCodes(kod) {
  if (!kod || typeof kod !== 'string' || !kod.length) {
    throw new Error('FlexiAPI - Received Offer/getPartnerWithCodes: kod param must be string with 1 or more characters')
  }
  const firmy = (await api.get(`/${EVIDENCE.ADRESAR}/(kod = '${kod}').json`, {
    detail: 'custom:kod,nazev'
  })).adresar

  if (firmy.length !== 1) {
    throw new Error(`FlexiAPI - Received Offer/getPartnerWithCodes: Unable to find partner with code '${kod}'`)
  }
  const firma = firmy[0]

  const dodava = (await api.get(`/${EVIDENCE.DODAVATEL}/(firma = 'code:${kod}').json`, {
    detail: 'custom:cenik,kodIndi'
  })).dodavatel
  const odebira = (await api.get(`/${EVIDENCE.ODBERATEL}/(firma = 'code:${kod}').json`, {
    detail: 'custom:cenik,kodIndi'
  })).odberatel

  firma.cenikHashMap = new Map()
  firma.indiHashMap = new Map()
  firma.cenik = {}
  for (const prod of dodava) {
    if (firma.cenikHashMap.has(prod.cenik)) {
      continue
    }
    firma.cenikHashMap.set(prod.kod, prod.kodIndi)
    firma.indiHashMap.set(prod.kodIndi, prod.kod)
    firma.cenik[prod.kodIndi] = prod.kod
  }

  return firma
}

export async function getCenik() {
  const detail = 'kod,eanKod,nazev'
  return await commonGetCenik(api, CI.EANS, detail)
}

export async function post(order, test) {
  const data = {
    cisDosle: order.cisDosle,
    datTermin: order.datTermin,
    poznam: order.poznam,
    typDokl: `code:${order.typDokl}`,
    mena: `code:${order.mena}`,
    firma: `code:${order.firma}`
  }

  data.polozkyDokladu = order.polozkyDokladu.map(item => {
    return {
      typPolozkyK: 'typPolozky.katalog',
      cenik: `code:${item.cenik}`,
      cenaMj: item.cenaMj,
      poznam: item.poznam,
      mjObjem: 'code:KS',
      objem: item.objem
    }
  })

  const evidence = EVIDENCE.NABIDKA_PRIJATA
  return await api.post(`/${evidence}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    [evidence]: [data]
  })
}

export default {
  getPartnerWithCodes,
  getCenik,
  post
}
