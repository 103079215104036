import { EVIDENCE, ATTRS, TAGGROUPS, MJ, CENIK_INCLUDES as CI } from './const.js'

export async function getAttributes(api, types, options) {
  if (!(types instanceof Array)) {
    types = [types]
  }
  if (!options) {
    options = {
      detail: 'custom:hodnota,cenik'
    }
  }
  const url = `/${EVIDENCE.ATRIBUT}/(typAtributu.kod in ('${types.join('\', \'')}')).json`
  return (await api.get(url, options)).atribut
}

export async function getCenik(api, includes, detail) {
  if (detail instanceof Array) {
    detail = `custom:${detail.join(',')}`
  } else if (typeof detail === 'string') {
    if (detail !== 'summary' && detail !== 'full' && detail !== 'id' && !detail.startsWith('custom:')) {
      detail = `custom:${detail}`
    }
  } else {
    detail = 'summary'
  }

  const inc = (sym) => includes.includes(sym)
  if (typeof includes === 'string') {
    includes = [includes]
  }
  if (!includes) {
    includes = []
  }

  const cenik = (await api.get(`/${EVIDENCE.CENIK}.json`, { detail })).cenik

  const otherEans = inc(CI.EANS) ? await getAttributes(api, ATTRS.EANS) : []
  const bqts = inc(CI.PAL) ? await getAttributes(api, ATTRS.BQT) : []
  const lqts = inc(CI.PAL) ? await getAttributes(api, ATTRS.LQT) : []
  const pqts = inc(CI.PAL) ? await getAttributes(api, ATTRS.PQT) : []
  const radyAttrs = inc(CI.SORT) ? await getAttributes(api, ATTRS.RADY) : []
  const typyAttrs = inc(CI.SORT) ? await getAttributes(api, ATTRS.TYPY) : []
  const znackyAttrs = inc(CI.SORT) ? await getAttributes(api, ATTRS.ZNACKY) : []

  const eanHashMap = new Map()
  const kodHashMap = new Map()

  cenik.forEach(produkt => {
    const p = produkt
    const pcode = `code:${p.kod}`

    kodHashMap.set(p.kod, p)

    if (p.eanKod) {
      p.eanKod = parseInt(p.eanKod) || null
      eanHashMap.set(p.eanKod, p)
    }

    p.otherEans = []
    if (inc(CI.EANS)) {
      p.otherEans = otherEans.filter(attr => attr.cenik === pcode).map(eanAttr => parseInt(eanAttr.hodnota) || null)
    }
    p.otherEans.forEach(ean => {
      if (ean) {
        eanHashMap.set(ean, p)
      }
    })

    Object.defineProperty(p, 'allEans', {
      get: function() { return [p.eanKod, ...p.otherEans] }
    })

    Object.defineProperty(p, 'ean', {
      get: function() { return p.eanKod }
    })

    if (typeof p.stitky === 'string') {
      const stitkyArr = p.stitky.split(', ')
      p.znacka = findByPrefix(stitkyArr, `${TAGGROUPS.ZNACKY}.`, true)
      p.typ = findByPrefix(stitkyArr, `${TAGGROUPS.TYPY}.`, true)
      p.rada = findByPrefix(stitkyArr, `${TAGGROUPS.RADY}.`, true)
      if (p.rada) {
        p.rada = p.rada.substring(p.znacka.length + 1)
      }
    }

    p.skupZboz = p.skupZboz ? p.skupZboz.substr(5) : undefined // remove 'code:'
    p.mj1 = p.mj1 ? (MJ[p.mj1.substr(5)] || null) : undefined // remove 'code:'
    p.mjObj = p.mjObj ? (MJ[p.mjObj.substr(5)] || null) : undefined // remove 'code:'
    p.mjHmot = p.mjHmot ? (MJ[p.mjHmot.substr(5)] || null) : undefined // remove 'code:'
    p.vyrobce = p.vyrobce ? p.vyrobce.substr(5) : undefined // remove 'code:'

    if (inc(CI.PAL)) {
      p.bqt = bqts.find(attr => attr.cenik === pcode) || null
      p.lqt = lqts.find(attr => attr.cenik === pcode) || null
      p.pqt = pqts.find(attr => attr.cenik === pcode) || null
      p.bqt = p.bqt ? parseInt(p.bqt.hodnota) : null
      p.lqt = p.lqt ? parseInt(p.lqt.hodnota) : null
      p.pqt = p.pqt ? parseInt(p.pqt.hodnota) : null
    }

    p.isSizeObj = () => p.mjObj && p.mjObj.length
    p.getSizeMj = () => p.isSizeObj() ? p.mjObj : p.mjHmot
    p.getSize = () => parseInt(p.isSizeObj() ? p.objem : p.hmotMj)

    p.velikost = p.getSize()
    p.velikost_mj = p.getSizeMj()

    if (inc(CI.SORT)) {
      p.znackaAttr = znackyAttrs.find(za => za.cenik === pcode)
      p.radaAttr = radyAttrs.find(za => za.cenik === pcode)
      p.typAttr = typyAttrs.find(za => za.cenik === pcode)
    }
  })

  cenik.byEan = (ean) => {
    if (!ean) {
      return null
    }
    ean = parseInt(ean)
    return eanHashMap.get(ean)
  }

  cenik.byKod = (kod) => {
    if (!kod) {
      return null
    }
    return kodHashMap.get(kod)
  }

  return cenik
}

export function findByPrefix(array, prefix, removePrefix) {
  const ret = array.find(elt => elt.substring(0, prefix.length) === prefix) || null
  if (ret && removePrefix) {
    return ret.substring(prefix.length)
  }
  return ret
}
