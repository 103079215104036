import { api } from './flexiSession.js'
import { EVIDENCE, ATTRS, TAGGROUPS, MJ, CENIK_INCLUDES as CI } from './const.js'
import { getAttributes, getCenik, findByPrefix } from './common.js'

let znacky = null
let typy = null
let rady = null
let skupiny = null

export async function get(nocache) {
  const detail = 'kod,eanKod,nazev,stitky,skupZboz,vyrobce,mjHmot,mjObj,mj1,hmotMj,objem'
  return await getCenik(api, CI.ALL, detail)
}

export async function postWithOneDodavatel(data, test) {
  if (data.kod) {
    data.id = 'code:' + data.kod
    delete data.kod
  }

  data.skupZboz = `code:${data.skupZboz}`
  data.vyrobce = `code:${data.vyrobce}`
  data.mj1 = 'code:KS'
  data.skladove = true

  if (['pcs', 'ml'].indexOf(data.velikost_mj) >= 0) {
    data['mjHmot@remove'] = true
    data.mjObj = 'code:' + Object.keys(MJ).find(fk => MJ[fk] === data.velikost_mj)
    data['hmotMj@remove'] = true
    data.objem = data.velikost
  } else {
    data.mjHmot = 'code:' + Object.keys(MJ).find(fk => MJ[fk] === data.velikost_mj)
    data['mjObj@remove'] = true
    data.hmotMj = data.velikost
    data['objem@remove'] = true
  }
  delete data.velikost
  delete data.velikost_mj

  data.stitky = `${TAGGROUPS.ZNACKY}.${data.znacka},` +
                `${TAGGROUPS.TYPY}.${data.typ},` +
                `${TAGGROUPS.RADY}.${data.znacka}.${data.rada}`

  const attrsInput = {
    [`code:${ATTRS.ZNACKY}`] : data.znacka,
    [`code:${ATTRS.TYPY}`] : data.typ,
    [`code:${ATTRS.RADY}`] : data.rada,
    [`code:${ATTRS.BQT}`] : parseInt(data.bqt),
    [`code:${ATTRS.LQT}`] : parseInt(data.lqt),
    [`code:${ATTRS.PQT}`] : parseInt(data.pqt)
  }

  const ae = data.allEans
  for (let i = 1; i < ATTRS.EANS.length; i++) {
    attrsInput[`code:${ATTRS.EANS[i-1]}`]
      = (i < ae.length) ? ae[i] : null
  }
  const atributes = writeAtributyCeniku(data.id, attrsInput)

  delete data.allEans
  delete data.znacka
  delete data.typ
  delete data.rada
  delete data.bqt
  delete data.lqt
  delete data.pqt

  const res = await api.post(`/${EVIDENCE.CENIK}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    cenik: [data]
  })

  const attrRes = await api.post(`/${EVIDENCE.ATRIBUT}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    atribut: atributes
  })

  return [res, attrRes]
}

export async function getZnacky(nocache) {
  if (!znacky || nocache) {
    znacky = (await api.get(`/${EVIDENCE.STITEK}/(skupVybKlic = 'code:${TAGGROUPS.ZNACKY}').json`, {
      detail: 'custom:kod,nazev'
    })).stitek

    znacky.forEach(zn => zn.kod = zn.kod.substr(5))
  }
  return znacky
}

export async function postZnacka(data, test) {
  data.skupVybKlic = `code:${TAGGROUPS.ZNACKY}`

  if (data.kod) {
    data.id = 'code:' + 'BRND.' + data.kod
    delete data.kod
  }

  return await api.post(`/${EVIDENCE.STITEK}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    stitky: [data]
  })
}

export async function getSkupiny(nocache) {
  if (!skupiny || nocache) {
    skupiny = (await api.get(`/${EVIDENCE.SKUPZBOZ}.json`, {
      detail: 'custom:kod,nazev,nazevA'
    }))['skupina-zbozi']
  }
  return skupiny
}

export async function postSkupina(data, test) {
  if (data.kod) {
    data.id = 'code:' + data.kod
    delete data.kod
  }

  return await api.post(`/${EVIDENCE.SKUPZBOZ}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    [EVIDENCE.SKUPZBOZ]: [data]
  })
}

export async function getTypy(nocache) {
  if (!typy || nocache) {
    typy = (await api.get(`/${EVIDENCE.STITEK}/(skupVybKlic = 'code:${TAGGROUPS.TYPY}').json`, {
      detail: 'custom:kod,nazev'
    })).stitek

    typy.forEach(ty => ty.kod = ty.kod.substr(5))
  }

  return typy
}

export async function postTyp(data, test) {
  data.skupVybKlic = `code:${TAGGROUPS.TYPY}`

  if (data.kod) {
    data.id = 'code:' + 'TYPE.' + data.kod
    delete data.kod
  }

  return await api.post(`/${EVIDENCE.STITEK}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    stitky: [data]
  })
}

export async function getRady(nocache) {
  if (!rady || nocache) {
    rady = (await api.get(`/${EVIDENCE.STITEK}/(skupVybKlic = 'code:${TAGGROUPS.RADY}').json`, {
      detail: 'custom:kod,nazev'
    })).stitek

    rady.forEach(ra => {
      const splt = ra.kod.split('.')
      ra.znacka = null
      if (splt.length >= 3) {
        ra.kod = splt[2]
        ra.znacka = splt[1]
      }
    })
  }
  return rady
}

export async function postRada(data, test) {
  data.skupVybKlic = `code:${TAGGROUPS.RADY}`

  if (data.kod) {
    data.id = 'code:' + data.kod
    data.id = 'code:' + 'LINE.' + data.znacka +'.' + data.kod
    delete data.kod
  }

  return await api.post(`/${EVIDENCE.STITEK}.json`, {
    'dry-run': test ? 'true' : 'false'
  }, {
    stitky: [data]
  })
}

function writeAtributyCeniku(cenikId, atributy) {
  const res = []
  for (const key of Object.keys(atributy)) {
    if (!atributy[key] && atributy[key] !== 0) {
      res.push({
        '@filter': `(typAtributu = '${key}' AND cenik = '${cenikId}')`,
        '@action': 'delete',
        '@limit': 1
      })
    } else {
      res.push({
        typAtributu: key,
        cenik: cenikId,
        hodnota: atributy[key]
      })
    }
  }
  return res
}



export default {
  get,
  getZnacky,
  getSkupiny,
  getTypy,
  getRady,

  postZnacka,
  postSkupina,
  postTyp,
  postRada,
  postWithOneDodavatel
}
